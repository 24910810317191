import { useEffect } from 'react';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Locale } from '@storefront/lib-global';
import { CmsSlotsProvider } from '@storefront/lib-global/context/cmsSlotsContext';
import { PageLoader } from '@storefront/lib-global/globalComponents/Loader/PageLoader';
import { CmsSlot } from '@storefront/lib-global/globalComponents/cmsSlot';
import { MasterLayout } from '@storefront/lib-global/globalComponents/masterLayout';
import { TabNavigation } from '@storefront/lib-global/globalComponents/navigation/TabNavigation';
import { Meta } from '@storefront/lib-global/globalComponents/seo/Meta';
import { useIsUserLoggedIn } from '@storefront/lib-global/hooks/useIsUserLoggedIn';
import { getCmsSlotsDataForApplicationPages } from '@storefront/lib-global/services/amplience/getCmsSlotsData';
import { PageType } from '@storefront/lib-global/types/newrelic';
import { CMS_SLOTS_DELIVERY_KEYS } from '@storefront/lib-global/utils/amplience/constants';
import { setCustomCacheHeader } from '@storefront/lib-global/utils/setCustomCacheHeader';

/* eslint-disable promise/prefer-await-to-then */
const RegistrationForm = dynamic(() =>
  import('@storefront/lib-global/globalComponents/registration/registrationForm/RegistrationForm').then(
    (mod) => mod.RegistrationForm,
  ),
);
const LoginForm = dynamic(() => import('../../components/login/LoginForm').then((mod) => mod.LoginForm));
/* eslint-enable promise/prefer-await-to-then */

export default function LoginPage({ hostName, cmsSlotsData }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { t } = useTranslation(['lib-global-common']);
  const { isUserLoggedIn, loading: isUserLoggedInLoading } = useIsUserLoggedIn();

  const router = useRouter();
  useEffect(() => {
    if (isUserLoggedIn) {
      router.replace(
        {
          pathname: '/',
        },
        undefined,
        {
          locale: router.locale,
        },
      );
    }
  }, [isUserLoggedIn, router]);

  return (
    <>
      <Meta
        metaTitle={`Clarks Shoes & Footwear | ${t('login.title.page')}`}
        host={hostName}
        isDynamicContent
        pageType={PageType.LOGIN}
      />
      <CmsSlotsProvider cmsSlotsData={cmsSlotsData}>
        <MasterLayout>
          <CmsSlot deliveryKey={CMS_SLOTS_DELIVERY_KEYS.LOGIN_TOP_BANNER} />
          {isUserLoggedInLoading || isUserLoggedIn ? (
            <PageLoader />
          ) : (
            <TabNavigation joinUsTab={<RegistrationForm />} loginTab={<LoginForm />} />
          )}
        </MasterLayout>
      </CmsSlotsProvider>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ locale, res, req }) => {
  setCustomCacheHeader(res, { maxAge: 600, staleWhileRevalidate: 120 });

  const cmsSlotsProps = {
    locale: locale || Locale.DEFAULT_LOCALE,
    slug: ['login'],
    deliveryKeys: [CMS_SLOTS_DELIVERY_KEYS.LOGIN_TOP_BANNER],
  };
  const cmsSlotsData = await getCmsSlotsDataForApplicationPages(cmsSlotsProps);

  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', [
        'lib-global-common',
        'lib-global-countries-common',
        'lib-global-countries-common',
      ])),
      hostName: req.headers.host,
      cmsSlotsData,
    },
  };
};

import { LoadingIcon } from '../../icons';
import { LoaderHolder } from './Loader.styles';

type LoaderProps = {
  isDark?: boolean;
  loadingMessage?: string;
};

export function Loader({ isDark = false, loadingMessage }: LoaderProps) {
  return (
    <>
      <LoaderHolder $isDark={isDark} data-testid="threeDotsLoader">
        <LoadingIcon />
      </LoaderHolder>
      {loadingMessage && (
        <span className="visually-hidden" role="alert">
          {loadingMessage}
        </span>
      )}
    </>
  );
}

import { useEffect, useState } from 'react';

interface UseIsUserLoggedInProps {
  cache?: RequestInit['cache'];
}
export function useIsUserLoggedIn({ cache }: UseIsUserLoggedInProps = { cache: 'default' }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('/api/isUserLoggedIn', { cache });
        const { isUserLoggedIn: isLoggedIn } = await response.json();
        setIsUserLoggedIn(isLoggedIn);
      } catch (e) {
        console.error('Check if user is logged in error', e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    isUserLoggedIn,
  };
}

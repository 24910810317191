import styled from 'styled-components';
import { colours } from '../../stylings';

export const LoaderHolder = styled.div<{ $isDark: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  & > svg {
    fill: ${({ $isDark }) => ($isDark ? colours.BLACK : colours.WHITE)};
    height: 32px;
    width: 48px;
  }
  &:hover {
    & > svg {
      fill: ${colours.BLACK};
    }
  }
  &:focus {
    /* Empty focus state to satisfy linter */
  }
`;

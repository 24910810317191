import styled from 'styled-components';
import { TabNav } from '@radix-ui/themes';
import { BackButton } from '../../baseComponents/button';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

export const TabNavContainer = styled.div`
  max-width: 328px;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    max-width: 408px;
  }
`;

export const TabNavRoot = styled(TabNav.Root)`
  border-bottom: solid ${colours.BLACK} 1px;
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding-left: 0;
  width: 100%;

  li {
    flex: 1;
  }
`;

export const TabNavTrigger = styled(TabNav.Link)`
  background-color: inherit;
  border: none;
  color: ${colours.BLACK};
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  min-height: 40px;
  padding: 0 ${spacing.XXXS} ${spacing.XXXS};
  text-transform: uppercase;
  width: 100%;
  ${TypographyStyles.Headings.H4}
  &[data-active] {
    border-bottom: solid ${colours.BLACK} 4px;
    color: ${colours.MIDNIGHT_BLUE};
    cursor: default;
    font-weight: 600;
    opacity: 1;
  }

  /* Workaround to hide the extra hidden span that is being shown */
  span:nth-of-type(2) {
    display: none !important;
  }
`;

export const Back = styled(BackButton)`
  color: ${colours.BLACK};
  width: 100%;
`;

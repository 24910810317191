export const CMS_SLOTS_DELIVERY_KEYS = {
  PLP_TOP_SKINNY_BANNER: 'plp-top-skinny-banner',
  PLP_BANNER_SLOT_01: 'plp-banner-slot-01',
  PLP_BANNER_SLOT_02: 'plp-banner-slot-02',
  PLP_BANNER_SLOT_03: 'plp-banner-slot-03',
  PLP_BANNER_SLOT_04: 'plp-banner-slot-04',
  PLP_BANNER_SLOT_05: 'plp-banner-slot-05',
  PLP_CARD_SLOT_01: 'plp-card-slot-01',
  PLP_CARD_SLOT_02: 'plp-card-slot-02',
  PLP_CARD_SLOT_03: 'plp-card-slot-03',
  PLP_CARD_SLOT_04: 'plp-card-slot-04',
  PLP_CARD_SLOT_05: 'plp-card-slot-05',
  SRP_BANNER_SLOT_01: 'srp-banner-slot-01',
  SRP_BANNER_SLOT_02: 'srp-banner-slot-02',
  SRP_BANNER_SLOT_03: 'srp-banner-slot-03',
  SRP_BANNER_SLOT_04: 'srp-banner-slot-04',
  SRP_BANNER_SLOT_05: 'srp-banner-slot-05',
  SRP_CARD_SLOT_01: 'srp-card-slot-01',
  SRP_CARD_SLOT_02: 'srp-card-slot-02',
  SRP_CARD_SLOT_03: 'srp-card-slot-03',
  SRP_CARD_SLOT_04: 'srp-card-slot-04',
  SRP_CARD_SLOT_05: 'srp-card-slot-05',
  PDP_TOP_BANNER: 'pdp-top-banner',
  SRP_TOP_BANNER: 'srp-top-banner',
  CMS_TOP_BANNER: 'content-top-banner',
  BASKET_TOP_BANNER: 'basket-top-banner',
  CHECKOUT_TOP_BANNER: 'checkout-top-banner',
  FIND_ORDER_TOP_BANNER: 'find-order-top-banner',
  MY_ACCOUNT_TOP_BANNER: 'my-account-top-banner',
  BOOK_A_FITTING_TOP_BANNER: 'book-a-fitting-top-banner',
  GIFT_CARD_BALANCE_TOP_BANNER: 'gift-card-balance-top-banner',
  LOGIN_TOP_BANNER: 'login-top-banner',
  NEWSLETTER_UNSUBSCRIBE_TOP_BANNER: 'newsletter-unsubscribe-top-banner',
  REGISTER_TOP_BANNER: 'register-top-banner',
  RESET_PASSWORD_TOP_BANNER: 'reset-password-top-banner',
  STORE_LOCATOR_TOP_BANNER: 'store-locator-top-banner',
} as const;

export const CMS_SLOTS_PLP_BANNERS_DELIVERY_KEYS = [
  CMS_SLOTS_DELIVERY_KEYS.PLP_BANNER_SLOT_01,
  CMS_SLOTS_DELIVERY_KEYS.PLP_BANNER_SLOT_02,
  CMS_SLOTS_DELIVERY_KEYS.PLP_BANNER_SLOT_03,
  CMS_SLOTS_DELIVERY_KEYS.PLP_BANNER_SLOT_04,
  CMS_SLOTS_DELIVERY_KEYS.PLP_BANNER_SLOT_05,
];

export const CMS_SLOTS_PLP_CARDS_DELIVERY_KEYS = [
  CMS_SLOTS_DELIVERY_KEYS.PLP_CARD_SLOT_01,
  CMS_SLOTS_DELIVERY_KEYS.PLP_CARD_SLOT_02,
  CMS_SLOTS_DELIVERY_KEYS.PLP_CARD_SLOT_03,
  CMS_SLOTS_DELIVERY_KEYS.PLP_CARD_SLOT_04,
  CMS_SLOTS_DELIVERY_KEYS.PLP_CARD_SLOT_05,
];

export const CMS_SLOTS_SRP_BANNERS_DELIVERY_KEYS = [
  CMS_SLOTS_DELIVERY_KEYS.SRP_BANNER_SLOT_01,
  CMS_SLOTS_DELIVERY_KEYS.SRP_BANNER_SLOT_02,
  CMS_SLOTS_DELIVERY_KEYS.SRP_BANNER_SLOT_03,
  CMS_SLOTS_DELIVERY_KEYS.SRP_BANNER_SLOT_04,
  CMS_SLOTS_DELIVERY_KEYS.SRP_BANNER_SLOT_05,
];

export const CMS_SLOTS_SRP_CARDS_DELIVERY_KEYS = [
  CMS_SLOTS_DELIVERY_KEYS.SRP_CARD_SLOT_01,
  CMS_SLOTS_DELIVERY_KEYS.SRP_CARD_SLOT_02,
  CMS_SLOTS_DELIVERY_KEYS.SRP_CARD_SLOT_03,
  CMS_SLOTS_DELIVERY_KEYS.SRP_CARD_SLOT_04,
  CMS_SLOTS_DELIVERY_KEYS.SRP_CARD_SLOT_05,
];

export const CMS_SLOTS_APPLICATION_PAGES_BANNERS_DELIVERY_KEYS = [
  CMS_SLOTS_DELIVERY_KEYS.BASKET_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.CHECKOUT_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.FIND_ORDER_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.MY_ACCOUNT_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.BOOK_A_FITTING_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.GIFT_CARD_BALANCE_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.LOGIN_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.NEWSLETTER_UNSUBSCRIBE_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.REGISTER_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.RESET_PASSWORD_TOP_BANNER,
  CMS_SLOTS_DELIVERY_KEYS.STORE_LOCATOR_TOP_BANNER,
];

export const CMS_SLOTS_BY_TYPE = {
  PLP: {
    BANNERS: CMS_SLOTS_PLP_BANNERS_DELIVERY_KEYS,
    CARDS: CMS_SLOTS_PLP_CARDS_DELIVERY_KEYS,
  },
  SRP: {
    BANNERS: CMS_SLOTS_SRP_BANNERS_DELIVERY_KEYS,
    CARDS: CMS_SLOTS_SRP_CARDS_DELIVERY_KEYS,
  },
};

export const CMS_COMPONENTS_DISPLAY_PROPERTIES = {
  PLP: {
    BANNER_POSITION: 'bannerRow',
    CARD_POSITION: 'cardPosition',
  },
  PDP: {
    SKU: 'sku',
    GENDER: 'gender',
    PRODUCT_TYPE: 'productType',
    BRAND: 'brand',
    SKUS_TO_EXCLUDE: 'skusToExclude',
  },
  SRP: {
    BANNER_POSITION: 'bannerRow',
    CARD_POSITION: 'cardPosition',
  },
} as const;

// Creates a Union type from values in deeply nested objects
type DeepValues<T> = T extends object ? (T[keyof T] extends object ? DeepValues<T[keyof T]> : T[keyof T]) : never;

export type CmsComponentsDisplayProperties = DeepValues<typeof CMS_COMPONENTS_DISPLAY_PROPERTIES>;
export type CmsSlotsDeliveryKeys = typeof CMS_SLOTS_DELIVERY_KEYS[keyof typeof CMS_SLOTS_DELIVERY_KEYS];

import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { SectionContainer } from '../../baseComponents/containers/SectionContainer';
import { transformTextToId } from '../../utils/transformers';
import { Back, TabNavContainer, TabNavRoot, TabNavTrigger } from './TabNavigation.styles';

export function TabNavigation({
  joinUsTab,
  loginTab,
  slug,
}: {
  joinUsTab: ReactNode;
  loginTab: ReactNode;
  slug?: string;
}) {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const [isChangingTab, setIsChangingTab] = useState(false);

  const joinUs = t('register.join.us');
  const login = t('register.login');
  const joinUsId = transformTextToId(joinUs);
  const loginId = transformTextToId(login);

  const isLogin = router.asPath.includes('/login');
  const currentTabValue = isLogin ? loginId : joinUsId;
  const joinUsPath = slug || '/register';
  const query = router.pathname ? router.query : null;

  const tabs = useMemo(
    () => [
      { label: login, id: loginId },
      { label: joinUs, id: joinUsId },
    ],
    [login, loginId, joinUs, joinUsId],
  );

  const onChangeActiveTab = useCallback(
    (value: string) => {
      if (value === currentTabValue) return;

      setIsChangingTab(true);
      router.push(
        {
          pathname: value === loginId ? '/login' : joinUsPath,
          query: !isLogin ? query : '',
        },
        undefined,
        {
          locale: router.locale,
        },
      );
    },
    [loginId, router, currentTabValue, joinUsPath, isLogin, query],
  );

  const activeTabContent = useMemo(() => (isLogin ? loginTab : joinUsTab), [isLogin, loginTab, joinUsTab]);

  return (
    <SectionContainer>
      <TabNavContainer>
        <Back />
        <TabNavRoot>
          {tabs.map(({ label, id }) => (
            <TabNavTrigger asChild active={id === currentTabValue} key={id}>
              <button type="button" onClick={() => onChangeActiveTab(id)} disabled={isChangingTab}>
                {label}
              </button>
            </TabNavTrigger>
          ))}
        </TabNavRoot>
        {activeTabContent}
      </TabNavContainer>
    </SectionContainer>
  );
}

export default TabNavigation;
